import React from "react";
import {graphql, HeadProps, PageProps} from "gatsby";

import Layout from "../components/Layout";
import {GatsbyImageCustomType} from "../types/gatsby-image.type";
import {SiteMetadataConfigProps} from "../types/metadata-config-props.type";
import {SiteMetadata} from "../types/site-metadata.type";
import TextFormatter from "../utils/TextFormatter";
import {GatsbyImage} from "gatsby-plugin-image";
import CustomLink from "../components/shared/custom-link/CustomLink";
import {PageContextType} from "../types/page-context.type";
import StructuredData from '../assets/structured-data';

type OrderProps = {
  strapiOrder: {
    title: string,
    titleSubscriptionDevice: string,
    titleSubscription: string,
    urlSubscriptionDevice: string,
    urlSubscription: string,
    buttonSubscriptionDevice: string,
    buttonSubscription: string,
    description: {
      data: {
        description: string
      },
    },
    image1: GatsbyImageCustomType,
    image2: GatsbyImageCustomType,
    siteMetadata: SiteMetadata,
  }
} & SiteMetadataConfigProps

const Order = ({data: {strapiOrder}, pageContext}: PageProps<OrderProps, PageContextType>) => {
  const {title, description, image1, image2, buttonSubscription,
    buttonSubscriptionDevice, titleSubscription, titleSubscriptionDevice,
    urlSubscription, urlSubscriptionDevice, siteMetadata} = strapiOrder;

  const formattedDescription = TextFormatter.concatOrphans(description.data.description) || description.data.description;
  return(
    <Layout crumbs={pageContext.breadcrumb.crumbs} crumbLabel={siteMetadata.crumbLabel}>
      <section className={'flex flex-col pt-10 max-w-[1420px] mx-auto'}>
        <div className={'flex flex-col text-center gap-4 py-10 max-w-[850px] mx-auto px-5 sm:px-10 md:px-20'}>
          <h1 className={'font-head text-4xl md:text-5xl'}>{title}</h1>
          <div className={'text-lg text-neutrals-40'} dangerouslySetInnerHTML={{__html: formattedDescription}}/>
        </div>

        <div className={'flex flex-col md:flex-row items-center justify-center xl:rounded-t overflow-hidden text-center'}>
          <div className={'flex flex-col items-center justify-center pb-20 gap-7 bg-tertiary-40 relative w-full xl:w-1/2 md:h-[544px]'}>
            <div className={'mt-12'}>
              <GatsbyImage alt={image1.alternativeText} image={image1.localFile.childImageSharp.gatsbyImageData} />
            </div>
            <h2 className={'text-xl font-semibold text-white px-5 max-w-[250px]'}>{titleSubscription}</h2>
            <CustomLink className={'mtr-btn-large mtr-btn-[secondary]'} isInternal={false} to={urlSubscription}>
              <span>{buttonSubscription}</span>
            </CustomLink>
            <div className={'bg-squares hidden lg:flex absolute top-5 left-5 h-[156px] w-[90px] z-10'}></div>
          </div>

          <div className={'flex flex-col items-center justify-center pb-20 gap-7 bg-primary-60 relative w-full xl:w-1/2 md:h-[544px]'}>
            <div className={'mt-12'}>
              <GatsbyImage alt={image2.alternativeText} image={image2.localFile.childImageSharp.gatsbyImageData} />
            </div>
            <h2 className={'text-xl font-semibold text-white px-5 max-w-[300px]'}>{titleSubscriptionDevice}</h2>
            <CustomLink className={'mtr-btn-large mtr-btn-[secondary]'} isInternal={false} to={urlSubscriptionDevice}>
              <span>{buttonSubscriptionDevice}</span>
            </CustomLink>
            <div className={'bg-squares-white hidden lg:flex absolute bottom-5 right-5 h-[156px] w-[90px] z-10'}></div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Order;

export const Head = (props: HeadProps<OrderProps>) => {
  const slug = props.location.pathname;
  const siteUrl = props.data.site.siteMetadata.siteUrl;
  const {title, description, crumbLabel} = props.data.strapiOrder.siteMetadata;
  return (
    <>
      <title>{title}</title>
      <meta name="description" content={description}/>
      <meta name="robots" content="index, follow"/>
      <meta name="theme-color" content="#24B25B"/>
      {StructuredData([
        {
          name: crumbLabel,
          item: siteUrl + slug
        }
      ])}
    </>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
        title
      }
    }
    strapiOrder {
      siteMetadata {
        crumbLabel
        description
        title
      }
      urlSubscriptionDevice
      urlSubscription
      titleSubscriptionDevice
      titleSubscription
      title
      description {
        data {
          description
        }
      }
      buttonSubscriptionDevice
      buttonSubscription
      image2 {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, height: 300)
          }
        }
      }
      image1 {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, height: 300)
          }
        }
      }
    }
    site {
      siteMetadata {
        description
        siteUrl
        title
      }
    }
  }
`
